import datepicker from 'js-datepicker'
import 'js-datepicker/src/datepicker';

const options = {
    customDays: [ 'НД','ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
    customMonths: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    position: 'br',
    minDate: new Date(),
    startDay: 1,
    formatter: (input, date, instance) => {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedDate = dd + '.' + mm + '.' + yyyy;
        input.value = formattedDate;
    }
};

// Застосовуємо до всіх елементів із класом .modal__datepicker
document.querySelectorAll('.modal__datepicker').forEach(element => {
    datepicker(element, options);
});

// Додатково перевіряємо наявність елемента з класом .modal__datepicker-login
const pickerTrigger = document.querySelector('.modal__datepicker-login');
if (pickerTrigger) {
    datepicker(pickerTrigger, options);
}
