import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const swiperForReviews = (selector, slides) => {
    const swiper = new Swiper(selector, {
        modules: [ Navigation, Pagination ],
        // Default parameters
        slidesPerView: slides,
        spaceBetween: 32,
        navigation: {
            nextEl: selector+' .swiper-button-next',
            prevEl: selector+' .swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: false,
        },
        breakpoints: {
            300:{
                slidesPerView: 1,
                spaceBetween: 20,
            },
            575:{
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 2,
            },
            1100: {
                slidesPerview: 3,
            }
        }
    })
}

const swiperForArticles = (selector, slides , nextElem , prevElem) => {
    const swiper = new Swiper(selector, {
        modules: [ Navigation, Pagination ],
        // Default parameters
        slidesPerView: slides,
        spaceBetween: 32,
        navigation: {
            nextEl: nextElem,
            prevEl: prevElem,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: false,
        },
        breakpoints: {
            300:{
                slidesPerView: 1,
                spaceBetween: 20,
            },
            575:{
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 2,
            },
            1100: {
                slidesPerview: 3,
            }
        }
    })
}

function runPopups() {
    const popUpClose = document.querySelectorAll('[data-close-pop-up="close"]');
    const btnForOpenPopUp = document.querySelectorAll('[data-pop-up]');

    const popUpForReviews = (popUp, id) => {
        // const reviewContent = document.getElementById(`review-${id}`)
        // const popUpContentString = reviewContent.outerHTML

        // console.log("popup", popUp, id)
        const siteURL = window.origin
        // console.log(siteURL+'/api/reviews/'+id)
        fetch(siteURL+'/api/reviews/'+id)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // console.log(data.data)
                let test = popUp.querySelector('.reviews');
                test.innerHTML = data.data
                // popUp.innerHTML = data.data
            })


                // const parser = new DOMParser();
        // const parseHtml = parser.parseFromString(popUpContentString, 'text/html');
        // const popUpContent = parseHtml.getElementById(`review-${id}`);
        // popUpContent.removeAttribute('id');
        // popUpContent.classList.add('response__cart-content-text_pop-up')
        //
        // const reviewPopUp = popUp.querySelector('.reviews');
        // reviewPopUp.innerHTML = popUpContent.outerHTML;
    }

    if (btnForOpenPopUp) {
        btnForOpenPopUp.forEach(btn => {

            btn.addEventListener('click', event => {
                const thisPopUp = document.getElementById(`${event.target.getAttribute('data-pop-up')}`);
                const reviewId = event.target.dataset?.reviewId;

                if (reviewId) {
                    // console.log(reviewId)
                    popUpForReviews(thisPopUp, reviewId);
                }
                thisPopUp.dataset.open = "true"

            })
        })
    }

    if (popUpClose) {
        popUpClose.forEach(popUp => {
            popUp.addEventListener('click', event => {
                // console.log(event.target.parentElement)
                event.target.parentElement.dataset.open = "false";
            })
        })
    }

}
function runSwipers() {
    swiperForReviews('[data-swiper="textReviews"].swiper-for-3-slides', 3);
    swiperForReviews('[data-swiper="videoReviews"].swiper-for-3-slides', 3);
    swiperForReviews('[data-swiper="googleReviews"].swiper-for-3-slides', 3);
    swiperForReviews('[data-swiper="facebookReviews"].swiper-for-3-slides', 3);

    swiperForReviews('[data-swiper="articles-swiper"].swiper-for-2-slides', 2);
    swiperForReviews('[data-swiper="textReviews"].swiper-for-2-slides', 2);
    swiperForReviews('[data-swiper="videoReviews"].swiper-for-2-slides', 2);
    swiperForReviews('[data-swiper="googleReviews"].swiper-for-2-slides', 2);
    swiperForReviews('[data-swiper="facebookReviews"].swiper-for-2-slides', 2);

    swiperForArticles('#articles-swiper', 3 , '#articles-next-el' , '#articles-prev-el')
    swiperForArticles('#news-swiper', 3 ,'#news-next-el' ,'#news-prev-el')

}
runSwipers();
function runTabs() {
    const dataTabGroup = document.querySelectorAll('[data-tab-group]');
    if (dataTabGroup) {
        dataTabGroup.forEach((tabGroup) => {
            const dataTab = document.querySelectorAll(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-tab]`);
            const dataContent = document.querySelectorAll(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-content]`);

            const resetAllTab = () => {
                dataContent.forEach((content) => {
                    content?.classList.add('d-none');
                })

                dataTab.forEach((tab) => {
                    tab?.classList.remove('active');
                })
            }

            dataTab.forEach((tab) => {
                resetAllTab()
                tab.addEventListener('click', (event) => {
                    const thisTab = document.querySelector(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-tab="${event.target.dataset.tab}"]`);
                    const thisTabContent =  document.querySelector(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-content="${event.target.dataset.tab}"]`);

                    resetAllTab()
                    thisTabContent?.classList.remove('d-none');
                    thisTab?.classList.add('active')
                })
            })

            dataTab[0].click()
        })
    }
}
function runServicesAndDoctors() {
    const dataServices = document.querySelectorAll('[data-services]');
    const dataServicesName = document.querySelectorAll('[data-services-name]');
    const dataServicesMore = document.querySelectorAll('[data-services-more]');
    const dataDoctor = document.querySelectorAll('[data-doctor]');
    const dataDoctorName = document.querySelectorAll('[data-doctor-name]');
    const dataDoctorMore = document.querySelectorAll('[data-doctor-more]');

    dataServices?.forEach(el => {
        const haveService = (el.dataset.services === 'true')
        const servicesName = el.dataset.servicesName
        const servicesMore = el.dataset?.servicesMore

        const addMore = servicesMore ? `<span> +${servicesMore} </span>` : '';

        let renderHTML = `
            <span class="reviews__cart-text-service ${ haveService ? '' : 'reviews__cart-text-service_none' } small-text">
                <span class="name">
                    ${servicesName}
                </span>
                ${addMore}
            </span>
        `
        el.innerHTML = renderHTML
    })

    dataDoctor?.forEach(el => {
        const haveDoctor = (el.dataset.doctor === 'true')
        const doctorName = el.dataset.doctorName
        const doctorMore = el.dataset?.doctorMore

        const addMore = doctorMore ? `<span> +${doctorMore} </span>` : '';

        let renderHTML = `
            <span class="reviews__cart-doctor ${ haveDoctor ? '' : 'reviews__cart-doctor_none' } small-text">
                <span class="name">
                    ${doctorName}
                </span>
                ${addMore}
            </span>
        `
        el.innerHTML = renderHTML
    })
}
window.onload =  function(){

    const services = document.querySelector('[data-reviews="service"]')
    if (services) {

        const slug = document.querySelector('[data-service-slug]').getAttribute('data-service-slug')
        const siteURL = window.origin+'/'+document.documentElement.lang
        fetch(`${siteURL}/api/service/${slug}/reviews`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                services.innerHTML = data.data;
                runSwipers();
                runTabs();
                runPopups();
                runServicesAndDoctors();
            })
    }
    const item = document.querySelector('[data-reviews="main"]')
    if (item) {
        const siteURL = window.origin+'/'+document.documentElement.lang
        fetch(siteURL+'/api/main/reviews')
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                item.innerHTML = data.data;
                runSwipers();
                runTabs();
                runPopups();
                runServicesAndDoctors();
            })
    }


};

// todo : next code need refactoring

function isSliderDoctors(){
    let swiperMargin = new Swiper('#id', {
        modules: [ Navigation, Pagination ],
        // Default parameters
        slidesPerView: 2,
        initialSlide:0,
        spaceBetween: 24,
        navigation: {
            nextEl: '#doctors-service-next',
            prevEl: '#doctors-service-prev',
        },
        pagination: {
            el: '.paginate',
            clickable: true,
            dynamicBullets: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px

            // when window width is >= 480px
            300:{
                slidesPerView: 1,
            },

            575:{
                slidesPerView: 2,
                spaceBetween: 24,
                // centeredSlides:false,
            },
            1024:{
                // centeredSlides:true,
                slidesPerView: 1,
            },
            1200:{
                slidesPerView: 2,
            },
        }
    })
    if(swiperMargin.slides.length === 1){
        let swiperMargin = new Swiper('#id', {
            modules: [ Navigation, Pagination ],
            // Default parameters
            slidesPerView: 2,
            initialSlide:0,
            spaceBetween: 0,
        })
    }
}

isSliderDoctors()


function isSlider() {

    return new Swiper('#swiper-main', {
        modules: [ Navigation, Pagination, Autoplay ],
        pagination: {
            el: '.swiper-pagination',
            clickable:true,
            dynamicBullets: true,
        },
        navigation: {
            nextEl: '#main-swiper-next',
            prevEl: '#main-swiper-prev',
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },

        // Default parameters
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: true,
        simulateTouch:true,
        grabCursor: true,

    })
}
isSlider()


function isSlider3() {

    return new Swiper('#swiper-partners', {
        modules: [ Navigation],
        // loop:true,
        // initialSlide: 5.5,
        spaceBetween: 32,
        // slidesPerView: 5.5,
        // slidesPerGroup: 1,
        // centeredSlides: true,
        simulateTouch:true,
        grabCursor: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.5,
            },
            370: {
                slidesPerView: 1.8,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3.5,
            },
            // when window width is >= 640px
            991: {
                slidesPerView: 3.5,
            },
            1200: {
                slidesPerView: 5
            },
            1440:{
                slidesPerView: 7
            },
            1920:{
                slidesPerView: 9
            },
        }
    })
}
isSlider3()

function isSliderBlog(){
    return new Swiper('#sertificate-swiper', {
        modules: [ Navigation, Pagination ],
        // Default parameters
        slidesPerView: 3,
        spaceBetween: 32,
        navigation: {
            nextEl: '#blog-next-el',
            prevEl: '#blog-prev-el',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            // dynamicBullets: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px

            // when window width is >= 480px
            300:{
                slidesPerView: 1,
                spaceBetween: 20,
            },

            575:{
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,

            },
            991: {
                slidesPerView: 2,

            },
            1100: {
                slidesPerview: 3,

            }
        }
    })
}

isSliderBlog()

function isSlider2() {

    return new Swiper('#swiper-services', {
        modules: [ Navigation, Pagination ],
        pagination: {
            el: '.swiper-pagination',
            clickable:true,
            dynamicBullets: true,
        },

        // Default parameters
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: true,
        simulateTouch:true,
        grabCursor: true,

    })
}
isSlider2()
