const dataServices = document.querySelectorAll('[data-services]');
const dataServicesName = document.querySelectorAll('[data-services-name]');
const dataServicesMore = document.querySelectorAll('[data-services-more]');
const dataDoctor = document.querySelectorAll('[data-doctor]');
const dataDoctorName = document.querySelectorAll('[data-doctor-name]');
const dataDoctorMore = document.querySelectorAll('[data-doctor-more]');

dataServices?.forEach(el => {
    const haveService = (el.dataset.services === 'true')
    const servicesName = el.dataset.servicesName
    const servicesMore = el.dataset?.servicesMore

    const addMore = servicesMore ? `<span> +${servicesMore} </span>` : '';

    let renderHTML = `
        <span class="reviews__cart-text-service ${ haveService ? '' : 'reviews__cart-text-service_none' } small-text">
            <span class="name">
                ${servicesName}
            </span>
            ${addMore}
        </span>
    `
    el.innerHTML = renderHTML
})

dataDoctor?.forEach(el => {
    const haveDoctor = (el.dataset.doctor === 'true')
    const doctorName = el.dataset.doctorName
    const doctorMore = el.dataset?.doctorMore

    const addMore = doctorMore ? `<span> +${doctorMore} </span>` : '';

    let renderHTML = `
        <span class="reviews__cart-doctor ${ haveDoctor ? '' : 'reviews__cart-doctor_none' } small-text">
            <span class="name">
                ${doctorName}
            </span>
            ${addMore}
        </span>
    `
    el.innerHTML = renderHTML
})
