// HTML layout for language modal
const languageModalHTML = `
<div class="modal-window" id="langModal" data-colse="false">
    <div class="modal-window__container language-modal">
        <p class="h2">Оберіть мову</p>
        <div class="language-modal__buttons-block">
            <button class="red" data-lang="uk">Українська</button>
            <button data-lang="ru">Російська</button>
        </div>
        <p>
            Щоб користуватись сайтом, потрібно обрати мову
        </p>
    </div>
</div>
`
async function sendEmptyRequest() {
    try {
      const response = await fetch('/api/locale/set', {
        method: 'GET',
      });

      if (response.ok) {
        return true
      } else {
        return false
      }
    } catch (error) {
        window.location.reload()
    }
  }

  function checkAndDisplayModal() {
    let checkLanguage = localStorage.getItem("show_localization_modal");

    if (checkLanguage === "true") {
      document.body.insertAdjacentHTML('beforeend', languageModalHTML);
      document.querySelectorAll('#langModal button').forEach(button => {
        button.addEventListener('click', async function() {
        //   let modalElement = document.getElementById('langModal');
          // modalElement.style.display = 'none';
          const selectedLanguage = this.getAttribute('data-lang');
          // console.log(selectedLanguage)
          //const requestSent = await sendEmptyRequest();
          const requestSent = await sendEmptyRequest();
          if (requestSent) {
            const currentUrl = new URL(window.location.href);
            const currentPath = currentUrl.pathname;
            const currentSearchParams = currentUrl.search; // Отримання GET-параметрів

            const langRegex = /^\/([a-zA-Z]{2})(?:[^a-zA-Z]|$)/;
            const matches = currentPath.match(langRegex);
            if (matches && matches.length > 0) {
              const matchedLangPart = matches[0];
              const newUrl = currentPath.replace(matchedLangPart, `/${selectedLanguage}/`);
              const finalUrl = `${currentUrl.origin}${newUrl}${currentSearchParams}`;
              window.location.href = finalUrl;
            }
          }
        });
      });
    }
  }


checkAndDisplayModal()
