const popUpClose = document.querySelectorAll('[data-close-pop-up="close"]');
const btnForOpenPopUp = document.querySelectorAll('[data-pop-up]');

const popUpForReviews = (popUp, id) => {
    // const reviewContent = document.getElementById(`review-${id}`)
    // const popUpContentString = reviewContent.outerHTML

    // console.log("popup", popUp, id)
    const siteURL = window.origin
    // console.log(siteURL+'/api/reviews/'+id)
    fetch(siteURL+'/api/reviews/'+id)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            // console.log(data.data)
            let test = popUp.querySelector('.reviews');
            test.innerHTML = data.data
            // popUp.innerHTML = data.data
        })


            // const parser = new DOMParser();
    // const parseHtml = parser.parseFromString(popUpContentString, 'text/html');
    // const popUpContent = parseHtml.getElementById(`review-${id}`);
    // popUpContent.removeAttribute('id');
    // popUpContent.classList.add('response__cart-content-text_pop-up')
    //
    // const reviewPopUp = popUp.querySelector('.reviews');
    // reviewPopUp.innerHTML = popUpContent.outerHTML;
}

if (btnForOpenPopUp) {
    btnForOpenPopUp.forEach(btn => {

        btn.addEventListener('click', event => {
            const thisPopUp = document.getElementById(`${event.target.dataset.popUp}`);
            const reviewId = event.target.dataset?.reviewId;

            if (reviewId) {
                // console.log(reviewId)
                popUpForReviews(thisPopUp, reviewId);
            }

            thisPopUp.dataset.open = "true"

        })
    })
}

if (popUpClose) {
    popUpClose.forEach(popUp => {
        popUp.addEventListener('click', event => {
            // console.log(event.target.parentElement)
            event.target.parentElement.dataset.open = "false";
        })
    })
}
